import { useApolloClient } from '@apollo/client';
import {
	BoardFragmentFragment,
	type UpdateBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import SVEditBoardCollaboratorsActionContainer from '@apps/www/src/www/containers/SVEditBoardCollaboratorsActionContainer';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import updateBoardMutation from '@apps/www/src/www/queries/updateBoardMutation';
import SVAvatarsStack from '@pkgs/shared-client/components/SVAvatarsStack';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputText } from '@pkgs/shared-client/components/SVFormInputs';
import SVFormLayout from '@pkgs/shared-client/components/SVFormLayout';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import SVToggle from '@pkgs/shared-client/components/SVToggle';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import { useState } from 'react';

const SVEditBoardModal = SVModal.create(({ board }: { board: BoardFragmentFragment }) => {
	const client = useApolloClient();
	const close = useModalClose();
	const [isPrivateBoard, setIsPrivateBoard] = useState(board.isPrivate);
	const [teamEditorHasPermissions, setTeamEditorHasPermissions] = useState(
		!!board.isTeamEditorAnEditor,
	);

	const authSettings = useAuthSettings(['maxBoardCollaborators']);

	const isTeamBoard = board.ownershipType === BoardOwnershipType.TEAM;

	const handleSubmit = useEventCallback(
		async (values: Omit<UpdateBoardMutationMutationVariables['input'], 'boardID'>) => {
			await client.mutate({
				mutation: updateBoardMutation,
				variables: {
					input: {
						boardID: board._id,
						...(!isTeamBoard && { isPrivate: isPrivateBoard }),
						isTeamEditorAnEditor: teamEditorHasPermissions,
						...values,
					},
				},
			});

			close();
		},
	);

	const hasAdminPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		board.role,
		BoardUserRole.ADMIN,
	);

	return (
		<div className="-sm:w-full w-[500px]">
			<SVModal.Title>Edit {isTeamBoard ? 'team ' : ' '}board</SVModal.Title>
			<SVForm
				onSubmit={handleSubmit}
				submitLabel="Save"
				initialValues={{
					name: board.name,
					description: board.description,
				}}
				submitAlignment="right"
				enableReinitialize={true}
				noPadding
			>
				{isTeamBoard ? (
					<SVFormLayout.P>
						Team boards enable collaboration among your entire team.
					</SVFormLayout.P>
				) : null}
				<div className={isTeamBoard ? 'mt-3' : 'mt-4'}>
					<SVInputText name="name" label="Board name" required={true} />
				</div>
				<div className="mt-6">
					<SVInputText name="description" label="Description (optional)" />
				</div>
				{!isTeamBoard ? (
					<div className="flex justify-between mt-6 mb-6">
						<div className="flex flex-col text-start">
							<div className="text-base font-semibold text-gray-500">
								Private Board
							</div>
							<div className="type-label text-muted">
								Only you and collaborators can see it.
							</div>
						</div>
						<SVToggle
							isPressed={isPrivateBoard}
							onClick={() => setIsPrivateBoard(!isPrivateBoard)}
							use="extension-settings"
						/>
					</div>
				) : null}

				{isTeamBoard ? (
					<div className="flex items-center justify-between mt-6 mb-6">
						<div className="flex flex-col text-start">
							<div className="text-base font-semibold text-gray-500">
								Team editor can edit
							</div>
							<div className="type-label text-muted">
								If disabled, only team owners, admins and curators can add/remove
								saves from this board.
							</div>
						</div>
						<SVToggle
							isPressed={teamEditorHasPermissions}
							onClick={() => setTeamEditorHasPermissions(!teamEditorHasPermissions)}
							use="extension-settings"
						/>
					</div>
				) : null}

				{!isTeamBoard ? (
					<div className="flex items-center justify-between mb-6">
						<div className="text-base font-semibold text-gray-500">Collaborators</div>
						<SVEditBoardCollaboratorsActionContainer
							board={board}
							isAdmin={hasAdminPrivileges}
							render={(props) => (
								<div {...props}>
									<SVAvatarsStack
										users={board.collaborators}
										showDropdown={false}
										invites={board.invites}
										size={SVAvatarsStack.SIZES.EXTRA_LARGE}
										showAdd={
											(hasAdminPrivileges &&
												authSettings &&
												board.collaborators.length + board.invites.length <
													authSettings?.maxBoardCollaborators) ||
											false
										}
										visibleCount={3}
										{...props}
									/>
								</div>
							)}
						/>
					</div>
				) : null}
			</SVForm>
		</div>
	);
});

export default SVEditBoardModal;
