import {
	type User,
	type UserFragmentFragment,
	type UserQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import SVGridSortButtonContainer from '@apps/www/src/www/containers/SVGridSortButtonContainer';
import { openNav } from '@apps/www/src/www/reducers/ui';
import SVA from '@pkgs/shared-client/components/SVA';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVAvatar from '@pkgs/shared-client/components/SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVProBadge from '@pkgs/shared-client/components/SVProBadge';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import { fixURL, prettyURL } from '@pkgs/shared-client/helpers/format';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconMenuSVG from '@pkgs/shared-client/img/icon-menu-three-lines-inlined.svg';
import InstagramIconSVG from '@pkgs/shared-client/img/instagram-inlined.svg';
import TwitterIconSVG from '@pkgs/shared-client/img/twitter-inlined.svg';
import normalizeSocialProfileLink from '@pkgs/shared/helpers/normalizeSocialProfileLink';
import React from 'react';
import { useDispatch } from 'react-redux';

const _SubmenuLabel = (props: OnlyChildrenProps) => <span className="block" {...props} />;
const _Number = (props: OnlyChildrenProps) => <span className="-smp:block" {...props} />;

const _BadgeNotifications = React.memo(() => (
	<span className="theme-dark flex-center type-label bg-brand text-primary duration-over absolute right-0 top-0 h-3 w-3 rounded-full transition ease-out group-hover:brightness-75"></span>
));

const _SmallAvatarItem = React.memo(
	({
		onOpenNav,
		userURL,
		unreadNotificationsCount,
	}: Pick<Props, 'onOpenNav' | 'userURL' | 'unreadNotificationsCount'>) => {
		return (
			<SVLink
				className="text-primary relative z-10 flex h-10 w-10 items-center justify-center rounded-full align-middle"
				to={'/r/'}
				title="Open menu"
				onClick={preventDefault(onOpenNav)}
			>
				<IconMenuSVG />
				{unreadNotificationsCount > 0 ? <_BadgeNotifications /> : ''}
			</SVLink>
		);
	},
);

const _Navigation = ({
	asOwner,
	user,
}: {
	asOwner: boolean;
	user: UserQueryQuery['userByUsername'];
}) => {
	const subnavItemsConfig = [
		{
			url: `${user.url}`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.itemsCount)}</_Number>{' '}
					{plural(user.itemsCount, 'Save', null, true)}
				</_SubmenuLabel>
			),
		},
		(user.boardsCount > 0 || asOwner) && {
			url: `${user.url}boards/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.boardsCount)}</_Number>{' '}
					{plural(user.boardsCount, 'Board', null, true)}
				</_SubmenuLabel>
			),
		},
		(user.followingCount > 0 || asOwner) && {
			url: `${user.url}following/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.followingCount)}</_Number> Following
				</_SubmenuLabel>
			),
		},
		(user.followersCount > 0 || asOwner) && {
			url: `${user.url}followers/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.followersCount)}</_Number>{' '}
					{plural(user.followersCount, 'Follower', null, true)}
				</_SubmenuLabel>
			),
		},
	].filter(Boolean);

	const subnavItems = subnavItemsConfig.map((item) => (
		<SVActions.NavItem key={item.url} to={item.url}>
			{item.label}
		</SVActions.NavItem>
	));

	return <SVActions use={SVActions.USES.PAGENAV}>{subnavItems}</SVActions>;
};

const _Header = React.memo(
	({
		asOwner,
		user,
		onOpenNav,
		unreadNotificationsCount,
		userURL,
	}: {
		asOwner: boolean;
		user: UserFragmentFragment;
		onOpenNav: (event: React.UIEvent) => void;
		unreadNotificationsCount: number;
		userURL: User['url'];
	}) => {
		const info = [
			`@${user.username}`,
			user.role,
			user.company && (
				<SVA Component={SVLink} to={user.company.url}>
					{user.company.name}
				</SVA>
			),
		].filter(Boolean);

		const links = [
			user.website && (
				<SVA href={fixURL(user.website)} target="_blank" rel="noopener noreferrer">
					{prettyURL(user.website)}
				</SVA>
			),
			user.instagram && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.instagram, 'instagram')}
						src={InstagramIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.instagram}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
			user.twitter && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.twitter, 'twitter')}
						src={TwitterIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.twitter}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
		].filter(Boolean);

		const itemSeparator = <>&nbsp;&nbsp;•&nbsp;&nbsp;</>;

		return (
			<div className="relative mt-4 flex flex-col items-center space-y-2 pb-16">
				<div className="flex w-full items-center justify-between sm:justify-center">
					<div className="flex sm:hidden">
						<_SmallAvatarItem
							onOpenNav={onOpenNav}
							userURL={userURL}
							unreadNotificationsCount={unreadNotificationsCount}
						/>
					</div>
					<SVAvatar
						className="z-index-user-header-avatar border-background -mdp:h-20 -mdp:w-20 -mdp:border-4 -sm:h-16 -sm:w-16 -sm:border-[3px] relative h-32 w-32 rounded-full border-8"
						src={user.avatarURL}
					/>
					<div className="flex h-10 w-10 sm:hidden" />
				</div>
				<SVPageTitle className="type-small-title relative pb-2 font-normal">
					{user.isPro && (
						<SVProBadge
							className="-sm:top-0 invisible top-[-2px]"
							size={SVProBadge.SIZES.LARGE}
						/>
					)}
					{user.name}
					{user.isPro && (
						<SVProBadge
							className="-sm:top-0 top-[-2px]"
							size={SVProBadge.SIZES.LARGE}
						/>
					)}
				</SVPageTitle>
				<div>
					{info.map((item, index) => (
						<React.Fragment key={index}>
							{item}
							{index !== info.length - 1 && itemSeparator}
						</React.Fragment>
					))}
				</div>
				<div className="flex items-center">
					{links.map((item, index) => (
						<React.Fragment key={index}>
							{item}
							{index !== links.length - 1 && itemSeparator}
						</React.Fragment>
					))}
				</div>
				{user.canFollow && (
					<div className="pt-4">
						<SVFollowButtonContainer
							userID={user._id}
							canFollow={user.canFollow}
							isFollowing={user.isFollowing}
							isFollowingBack={user.isFollowingBack}
						/>
					</div>
				)}
				{asOwner && (
					<div className="space-x-3 pt-4">
						<SVButton Component={SVLink} to="/profile/" size={SVButtonSIZES.SMALL}>
							Edit Profile
						</SVButton>
						<SVButton
							Component={SVLink}
							to="/settings"
							size={SVButtonSIZES.SMALL}
							use={SVButtonUSES.OUTLINE}
						>
							Settings
						</SVButton>
					</div>
				)}
			</div>
		);
	},
);

type Props = {
	asOwner: boolean;
	user: UserQueryQuery['userByUsername'];
	onOpenNav: (event: React.UIEvent) => void;
	unreadNotificationsCount: number;
	userURL: User['url'];
};

const SVUserHeader = ({ asOwner, user, onOpenNav, unreadNotificationsCount, userURL }: Props) => {
	const dispatch = useDispatch();
	const handleOpenNav = useEventCallback(() => dispatch(openNav()));

	return (
		<div>
			<SVPageHeader className="-sm:mb-10 mb-8 mt-0">
				<_Header
					user={user}
					asOwner={asOwner}
					onOpenNav={handleOpenNav}
					unreadNotificationsCount={unreadNotificationsCount}
					userURL={userURL}
				/>
				<div className="relative flex flex-wrap justify-center ">
					<div className="-sm:order-0 -sm:basis-full -sm:pb-6 flex items-center">
						<_Navigation user={user} asOwner={asOwner} />
					</div>
					<div className="-sm:top-12 -lg:top-0 -lg:bottom-5 -sm:order-2 -sm:basis-1/2 absolute bottom-1 right-0 mt-3.5 flex flex-auto items-center justify-end space-x-6">
						{asOwner && <SVGridSortButtonContainer />}
					</div>
				</div>
			</SVPageHeader>
		</div>
	);
};

export default SVUserHeader;