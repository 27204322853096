import { gql } from '@apollo/client';
import BoardCollaboratorFragment from './BoardCollaboratorFragment';
import InviteFragment from './InviteFragment';

const BoardFragment = gql`
	${BoardCollaboratorFragment}
	${InviteFragment}

	fragment BoardFragment on Board {
		_id
		name
		description
		slug
		url
		isPrivate
		itemsCount
		lastItemAddedOrder
		updatedAt
		thumbnails {
			_id
			image {
				thumbnail
			}
		}
		ogImage {
			width
			height
			url
		}
		role
		collaborators {
			...BoardCollaboratorFragment
		}
		ownershipType
		invites {
			...InviteFragment
		}
		acceptURL
		rejectURL
		publicURL
		isTeamEditorAnEditor
	}
`;

export default BoardFragment;
