import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import IconChevronDownSVG from '@pkgs/shared-client/img/icon-chevron-down-inlined.svg';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import IconSortSVG from '@pkgs/shared-client/img/icon-sort-inlined.svg';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';

const SORT_METHODS = [
	{
		name: ItemsSortMethod.CUSTOM,
		label: 'Custom order',
	},
	{
		name: ItemsSortMethod.NEWEST_FIRST,
		label: 'Newest first',
	},
	{
		name: ItemsSortMethod.OLDEST_FIRST,
		label: 'Oldest first',
	},
];

const SVGridSortButtonContainer = () => {
	const [sortMethod, setSortMethod] = usePersistentSetting(
		PersistentSettingKeys.ITEMS_SORT_METHOD,
	);
	const [showGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);
	const viewportName = useViewportName();
	const isSmallNav = viewportName === 'small' || (!viewportName && ViewportKind.MOBILE);

	// Don't show up if page is not an items grid
	if (!showGridControls) {
		// To keep profile page navigation in the middle
		return <div className="w-5" />;
	}

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			className="pl-4"
			renderTrigger={({ isOpen: _, ...props }) =>
				isSmallNav ? (
					<SVIconButton
						src={IconSortSVG}
						Component="button"
						className="-sm:block dropdown-trigger relative hidden"
						title="Sort saves"
						{...props}
					/>
				) : (
					<button className="dropdown-trigger -sm:hidden relative block" {...props}>
						<div className="flex cursor-pointer items-center text-gray-400 hover:text-gray-200">
							<span className="whitespace-nowrap">
								{SORT_METHODS.find((method) => method.name === sortMethod)?.label}
							</span>
							<IconChevronDownSVG className="ml-2 mt-0.5 h-4 w-4 " />
						</div>
					</button>
				)
			}
			renderContent={() => (
				<SVDropdownContent.Links>
					<SVDropdownContent.Links.ItemLabel>Sort by</SVDropdownContent.Links.ItemLabel>
					{SORT_METHODS.map((sortMethodInfo) => (
						<SVDropdownContent.Links.Item
							isSelected={sortMethod === sortMethodInfo.name}
							key={sortMethodInfo.name}
							onClick={() => setSortMethod(sortMethodInfo.name)}
							className="min-w-[150px] px-2.5"
						>
							<span className="ml-2">{sortMethodInfo.label}</span>
						</SVDropdownContent.Links.Item>
					))}
				</SVDropdownContent.Links>
			)}
		/>
	);
};

export default SVGridSortButtonContainer;
