import { BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVEditBoardModal from '@apps/www/src/www/modals/SVEditBoardModal';
import SVModal from '@pkgs/shared-client/components/SVModal';
import EditBoardSVG from '@pkgs/shared-client/img/edit-board-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import { twMerge } from 'tailwind-merge';

const _ThumbItem = ({ imageURL }: { imageURL: string }) => (
	<div
		className="flex-1 basis-1/2 bg-gray-800 bg-cover bg-center bg-no-repeat"
		style={{ backgroundImage: `url('${imageURL}')` }}
	/>
);

const _PrivateIcon = () => (
	<div className="absolute right-2 top-2 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-70 p-2 backdrop-blur-lg">
		<IconLockSVG className="text-primary h-4 w-4" />
	</div>
);

const _EditIcon = ({ board }: { board: BoardFragmentFragment }) => (
	<button
		className="absolute bottom-2 right-2 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-70 p-2 opacity-0 backdrop-blur-lg transition-opacity duration-200 group-hover:opacity-100"
		onClick={(e) => {
			e.stopPropagation();
			e.preventDefault();
			SVModal.open(SVEditBoardModal, { board });
		}}
	>
		<EditBoardSVG className="text-primary h-4 w-4" />
	</button>
);

export default function SVBoardThumbnail({
	className,
	board,
	hideEditIcon = false,
}: {
	className?: string;
	board: BoardFragmentFragment;
	hideEditIcon?: boolean;
}) {
	const hasAdminPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		board.role,
		BoardUserRole.ADMIN,
	);

	return (
		<div
			className={twMerge(
				'duration-over theme-dark group relative overflow-hidden rounded-lg bg-gray-800 transition-opacity ease-out group-hover:opacity-80',
				className,
			)}
		>
			<div className="absolute flex h-full w-full flex-col items-stretch space-y-0.5">
				{(board.thumbnails?.length || 0) > 0 ? (
					<_ThumbItem imageURL={board.thumbnails[0].image.thumbnail} />
				) : null}
				{(board.thumbnails?.length || 0) > 1 ? (
					<div className="flex basis-1/2 flex-row items-stretch space-x-0.5">
						<_ThumbItem imageURL={board.thumbnails[1].image.thumbnail} />
						{(board.thumbnails?.length || 0) > 2 ? (
							<_ThumbItem imageURL={board.thumbnails[2].image.thumbnail} />
						) : null}
					</div>
				) : null}
			</div>
			{board.isPrivate && <_PrivateIcon />}
			{hasAdminPrivileges && !hideEditIcon && <_EditIcon board={board} />}
		</div>
	);
}
