import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import IconNotificationSVG from '@pkgs/shared-client/img/icon-notification-inlined.svg';
import IconNotificationMenuSVG from '@pkgs/shared-client/img/icon-notification-menu-inlined.svg';
import clsx from 'clsx';
import React from 'react';

type Props = {
	count?: number;
	onClick?: (event: React.UIEvent) => void;
	onMouseOver?: (event: React.UIEvent) => void;
	isOutsideTheMenu?: boolean;
	iconClassName?: string;
	isSmallNav?: boolean;
};

const SVNotificationIcon: React.ComponentType<Props> = React.forwardRef<
	HTMLButtonElement & HTMLAnchorElement,
	Props

>(({ count = 0, isOutsideTheMenu, isSmallNav, onClick: _, iconClassName, ...otherProps }, forwardedRef) => {
	const viewportName = useViewportName();

	const isSmall =
		viewportName === 'small' || viewportName === 'medium' || viewportName === 'medium-portrait';
		
	const NotificationIcon = isSmallNav ? IconNotificationMenuSVG : IconNotificationSVG;

	return count > 0 ? (
		<span
			className={clsx(
				'flex items-center justify-center text-[13px] leading-[20px] text-background duration-over bg-primary h-5 rounded-full py-3 font-medium transition ease-out group-hover:brightness-75',
				count > 9 && !isSmall ? 'w-auto px-2' : 'w-5 px-3',
				isSmall && '-md:ml-1 -sm:ml-0',
			)}
		>
			{formatCount(Math.min(isSmall ? 99 : 9999, count))}
		</span>
	) : isOutsideTheMenu ? (
		<NotificationIcon
			ref={forwardedRef}
			label="Notification"
			{...otherProps}
			className={clsx(
                "hover:text-gray-500",
                isSmallNav ? "w-full h-full pt-[1.5px]" :"w-6 h-6 text-gray-400 hover:text-gray-500",
                iconClassName
            )}
		/>
	) : (
		<NotificationIcon
			ref={forwardedRef}
			label="Notification"
			{...otherProps}
			className={clsx(
                isSmallNav ? "w-full h-full pt-[1.5px]" : "w-5 h-5 ml-1 -md:ml-2.5 -sm:ml-1", iconClassName)}
		/>
	);
});

export default SVNotificationIcon;
