// TODO: Extract functions to separate files and write unit tests
import CardBrand from '@pkgs/shared/enums/CardBrand';
import formatURL from '@pkgs/shared/helpers/formatURL';
import { addDays, isBefore } from 'date-fns';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import format from 'date-fns/format';
import urlParser from 'url';

export function formatDate(date: Date | null) {
	if (!date) {
		return '';
	}

	return format(date, 'MMM Do, YYYY');
}

export function isWithinNextDays(dateToCheck: Date, referenceDate: Date, days: number): boolean {
	const endDate = addDays(referenceDate, days);
	return isBefore(dateToCheck, endDate);
}

export function formatRelativeDate(date: Date) {
	return distanceInWordsToNow(date);
}

export function formatRelativeDateShort(date: Date, includeSuffix = false) {
	const formattedDate = distanceInWordsToNow(date, { addSuffix: includeSuffix });

	if (formattedDate === 'less than a minute') {
		return 'Now';
	}

	return formattedDate
		.replace(/ seconds?/, 's')
		.replace(/ minutes?/, 'm')
		.replace(/ hours?/, 'h')
		.replace(/ days?/, 'd')
		.replace(/ weeks?/, 'w')
		.replace(/ months?/, 'mo')
		.replace(/ years?/, 'y');
}

export function formatRelativeDateShortToday(date: Date, includeSuffix = false) {
	const formattedDate = distanceInWordsToNow(date, { addSuffix: includeSuffix });
	const now = new Date();
	const isToday =
		date.getDate() === now.getDate() &&
		date.getMonth() === now.getMonth() &&
		date.getFullYear() === now.getFullYear();

	if (isToday) {
		return 'Today';
	}

	if (formattedDate === 'less than a minute') {
		return 'Now';
	}

	return formattedDate
		.replace('about ', '')
		.replace(/ days?/, 'd')
		.replace(/ weeks?/, 'w')
		.replace(/ months?/, 'mo')
		.replace(/ years?/, 'y');
}

export function formatMaskedCard(last4: string, brandID: string | undefined | null) {
	if (brandID == CardBrand.AMEX) {
		return `•••• •••••• •${last4}`;
	}

	return `•••• •••• •••• ${last4}`;
}

export function prettyURL(url: string) {
	let formatted = formatURL(url);

	if (formatted.indexOf('://') > -1 && formatted.indexOf('://') <= 5) {
		const parsed = urlParser.parse(formatted);

		formatted = formatted.substr((parsed.protocol || 'http').length + 2);
	}

	if (formatted.indexOf('www.') === 0) {
		formatted = formatted.substr(4);
	}

	return formatted;
}

export function fixURL(url: string) {
	if (url.indexOf('http') !== 0) {
		url = 'https://' + url.trim();
	}

	return url;
}
