import { useApolloClient } from '@apollo/client';
import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import ReportItemsMutation from '@apps/www/src/www/queries/ReportItemsMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import SVFormLayout from '@pkgs/shared-client/components/SVFormLayout';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';

const SVReportItemsModal = SVModal.create(
	({ items, onSuccess }: { items: ItemFragmentFragment[]; onSuccess?: () => void }) => {
		const client = useApolloClient();
		const close = useModalClose();

		const handleSubmit = useEventCallback(async () => {
			const { data } = await client.mutate({
				mutation: ReportItemsMutation,
				variables: {
					input: {
						itemIDs: items.map((item) => item._id),
					},
				},
			});

			setTimeout(() => {
				const reportedItemIDs = data?.reportItems.reportedItemIDs;

				if (reportedItemIDs) {
					items
						.filter((item) => reportedItemIDs.includes(item._id))
						.forEach((item) => client.cache.evict({ id: client.cache.identify(item) }));
				}

				client.cache.gc();
			});
		});

		const handleSuccess = useEventCallback(async () => {
			await close();

			onSuccess && onSuccess();
		});

		return (
			<>
				<SVModal.Title>Report</SVModal.Title>
				<SVForm
					onSubmit={handleSubmit}
					onSuccess={handleSuccess}
					submitLabel={`Report ${plural(items.length, 'Save')}`}
					isDelete={true}
					submitAlignment="right"
					size={SVFormLayout.SIZES.FULL}
				>
					<SVFormLayout.P>
						You&apos;re about to report this as an inappropriate content for Savee.
						It&apos;ll instantly hide it from all places and be placed on a queue for
						manual review.
					</SVFormLayout.P>
				</SVForm>
			</>
		);
	},
	{
		className: 'w-[360px]',
	},
);

export default SVReportItemsModal;
